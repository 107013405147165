export default [
	{
		id: 1,
		icon: "fa-file-chart-pie",
		name: "Общая аналитика для группы респондентов",
		keys: "",
		description: "",
		link: "/analytics/base"
	},
	{
		id: 2,
		icon: "fa-chart-bar",
		name: "Сравнение групп",
		keys: "",
		description: "",
		link: "/analytics/group"
	},
	{
		id: 3,
		icon: "fa-chart-line",
		name: "Аналитика динамики среднего профиля",
		keys: "",
		description: "",
		link: "/analytics/dynamic"
	},
	{
		id: 4,
		icon: "fa-chart-line",
		name: "Аналитика при повторном тестировании",
		keys: "",
		description: "",
		link: "/analytics/repeated"
	},
	{
		id: 5,
		icon: "fa-chart-pie",
		name: "Аналитика по демографическим показателям",
		keys: "",
		description: "",
		link: "/analytics/demographic"
	},
	{
		id: 6,
		icon: "fa-chart-area",
		name: "Поиск респондентов, соответствующих эталонному профилю",
		keys: "",
		description: "",
		link: "/analytics/reference"
	},
	{
		id: 7,
		icon: "fa-table",
		name: "Сводная таблица по доступным курсам/тестам",
		keys: "",
		description: "",
		link: "/analytics/summary"
	}
]